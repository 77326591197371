import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Invoices() {
    return (
        <Container>
            <ul>
                <li>
                    <Link to="/domestic-invoice/">Domestic Invoice</Link>
                </li>
                <li>
                    <Link to="/internationl-invoice/">
                        International Invoice
                    </Link>
                </li>
                <li>
                    <Link to="/nextgenkerala-invoice/">
                        NextGen Kerala Invoice
                    </Link>
                </li>
                <li>
                    <Link to="/techatschool-invoice/">Tech@School</Link>
                </li>
            </ul>
        </Container>
    );
}

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
        display: flex;
        border: 1px solid #b5e2f0;
        background-color: #f5fbfd;
        border-radius: 10px;
        padding: 50px;
        flex-direction: column;
        li {
            list-style-type: none;

            a {
                display: block;
                background-color: #b5e2f0;
                margin-bottom: 15px;
                padding: 20px 50px;
                border-radius: 10px;
                text-align: center;
                text-decoration: none;
                color: #000;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
`;
